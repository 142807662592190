import React from 'react';
import { useNotificationContext } from '../contexts/notificationContext';
import { Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useTheme as useThemeG } from '@mui/material/styles';
import { useUser } from '../contexts/userContext';
import { useParams } from 'react-router-dom';
import { useTheme } from '../contexts/themeContext';
import { useMediaQuery } from '@mui/material';
import { color } from '@mui/system';



export const Notification = () => {
  const theme = useThemeG();
  const { user } = useUser();
  const { signup } = useParams();
  const { themeMode } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  
const ButtonStyle = {
  background: 'transparent',
  border: `1px solid ${themeMode === 'dark' ? '#fff' : '#2700C1'}`,
  color: `${themeMode === 'dark' ? '#fff' : '#2700C1'}`,
  borderRadius: '50px',
  minWidth: '120px',
  '&:hover': {
      backgroundColor: 'rgba(39, 0, 193, 0.2)' // 50% transparency of the specified color on hover
  }
};



  const { notification, clearNotification, dialogNotification, clearDialogNotification } = useNotificationContext();

  if (!notification && !dialogNotification) return null;

  const Brcolor = notification?.type === 'success' ? (themeMode === 'dark' ? "#111010" : "#EAEFF9")
    : notification?.type === 'warning' ? '#FF9966'
      : notification?.type === 'error' ? '#ffaacc' : '#CC3300';

  const color = notification?.type === 'success' ? (themeMode === 'dark' ? "#fff" : "#1700C9") : "#fff";

  return (
    <>
      {notification && (
        <Box sx={{ borderRadius: "15px", display: 'flex', alignItems: 'center', backgroundColor: Brcolor, padding: 1, px: 5, width: '100%', justifyContent: 'center' }}>
          <Typography variant="body1" sx={{ color: (themeMode === 'dark') ? "#fff" : "#1700C9", display: 'inline-block' }}>
            {notification.message}
          </Typography>
        </Box>
      )}
      {dialogNotification && (
        <Dialog open onClose={clearDialogNotification}>
          <DialogTitle sx={{ p: 5, fontWeight:"600", fontSize:'28px'}}>{dialogNotification.title}</DialogTitle>
          <DialogContent sx={{ p: 5 }} >
            <Typography variant="body1" sx={{  fontSize:'16px', color:themeMode === 'dark' ? "#fff" : "black" }}>
              {dialogNotification.message}
            </Typography>
          </DialogContent>
          <DialogActions  sx={{ p: 5,}}>
            <Button sx={{ ...ButtonStyle,border:'none' ,mt: 2 }} onClick={clearDialogNotification}>Close</Button>
            {dialogNotification.actionBtn && <Button sx={{ ...ButtonStyle ,mt: 2 }} onClick={()=>{dialogNotification.action(); clearDialogNotification()  }}>{dialogNotification.actionBtn} </Button>}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Notification;
