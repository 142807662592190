import React, { useState } from 'react';
import {
    Button, Typography, Box, 
Dialog, DialogContent, DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoPr from '../../assets/images/tagedLogoPr.svg';
import logoW from '../../assets/images/tagedLogoW.svg';
import { useTheme } from '../../contexts/themeContext';
import { useMediaQuery } from '@mui/material';
import { useTheme as useThemeG } from '@mui/material/styles';


export const Terms = ({ open, setOpen }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const  theme = useThemeG();
    const { themeMode, toggleTheme } = useTheme();
     const  isMobile = useMediaQuery(theme.breakpoints.down('md'));




    const handleSubmit = async (e) => {
        e.preventDefault();
    };


    const handleClose = () => { // Function to close the dialog
        setOpen(false);
        navigate('/fileDetection'); // Navigate to the root URL
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="register-dialog-title"
            BackdropProps={{
                sx: {
                    backgroundColor: 'transparent',
                    backdropFilter: 'blur(3px)',
                }
            }}
        >
            <DialogTitle
                sx={{ backgroundColor: themeMode === 'dark' ? '#000' : '#fff', }}
                id="register-dialog-title">

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {themeMode === 'dark' ? <img style={{ height: '38px', }} src={logoW} alt={''} /> :
                        <img style={{ height: '38px', }} src={logoPr} alt={''} />}
                </Box>
            </DialogTitle>

            <DialogContent  sx={{ backgroundColor: themeMode === 'dark' ? '#000' : '#fff' }}  >
                <Typography sx={{
                    fontSize: isMobile?'14px':'16px'  , textAlign: 'justify',
                    color: themeMode === 'dark' ? '#fff' : '#000'
                }}>
                    {t("termsAndConditions")}
                </Typography>

                <Button onClick={handleClose} sx={{ mt: 4, mb: 6, width:'100%',
                    color: themeMode === 'dark' ? '#000' : '#fff',
                    backgroundColor: themeMode === 'dark' ? '#fff' : '#000',

                   }}>
                    {t("acceptTerms")}
                </Button>
            </DialogContent>
        </Dialog>
    );
}

export default Terms;
