import React from 'react';
import {
    Button, Typography, Box, Dialog, DialogContent, DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import {signup,signin} from '../../services/AuthService';
import logoPr from '../../assets/images/tagedLogoPr.svg';
import logoW from '../../assets/images/tagedLogoW.svg';
import { useTheme } from '../../contexts/themeContext';


export const PrivacyPolicyDialog = ({ open, setOpen }) => {
   const navigate = useNavigate();
   const { t } = useTranslation();
   const { themeMode } = useTheme();


   const title = (t) => {
        return (
            <Typography sx={{my:2, fontSize: '16px',fontWeight:'bold',  textAlign: 'center', color: themeMode === 'dark' ? '#fff' : '#000' }}>
                {t}
            </Typography>
        );
    };
    
    const text = (t) => {
        return (
            <Typography sx={{ fontSize: '16px', textAlign: 'justify', color: themeMode === 'dark' ? '#aaa' : '#777' }}>
                {t}
            </Typography>
        );
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    // };


    const handleClose = () => { // Function to close the dialog
        navigate('/fileDetection'); // Navigate to the root URL
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="register-dialog-title"
            BackdropProps={{
                sx: {
                    backgroundColor: 'transparent',
                    backdropFilter: 'blur(3px)',
                }
            }}
        >
            <DialogTitle
                sx={{ backgroundColor: themeMode === 'dark' ? '#000' : '#fff', }}
                id="register-dialog-title">

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {themeMode === 'dark' ? <img style={{ height: '38px', }} src={logoW} alt={''} /> :
                        <img style={{ height: '38px', }} src={logoPr} alt={''} />}
                </Box>
            </DialogTitle>

            <DialogContent  sx={{ backgroundColor: themeMode === 'dark' ? '#000' : '#fff' }}  >
                     
                {title("Privacy Policy Overview")}
                {text(`Welcome to BellRock AI. Our privacy policy outlines how we collect, use, and protect your information when you interact with our website bellrock.com, and use our AI-based tagging, labeling, and classification services, including our mobile applications and related services ("Services").`)}
                {title("Information We Collect")}
                {text("Provided by You: We collect information that you provide directly when signing up, purchasing, creating an account, or registering for our Services. This includes contact details, payment information, demographic data, and any content you choose to publish or share with us. Automated Collection: We use cookies, pixels, and similar technologies to collect information about your device and interaction with our Services. This includes IP addresses, device and browser types, and usage details.")}
                {title("How We Use Your Information")}
                {text("Service Delivery: Your information helps us process requests, provide customer support, customize content, develop new features, and update Services automatically. Communication: We communicate with you about the Services through notifications and marketing communications, and respond to your inquiries and feedback. Analysis and Development: We analyze user interaction to optimize performance, conduct market research, and support our operational needs.")}
                {title("Your Choices and Rights")}
                {text("Opting Out: You can opt out of marketing emails and texts through the unsubscribe links provided in those communications. Manage your email preferences in your account settings for further customization. Cookies and Browser Settings: Modify your browser settings to refuse or control cookies. Note, this may affect the functionality of certain Services. Account Information: Access, update, or delete your account information by logging into your account or by contacting us at privacy@bellrock.ai.")}
                {title("Changes to This Policy")}
                {text("We may update this Privacy Policy periodically to reflect changes in our practices. Significant changes will be notified on our website, so please review this policy regularly.")}   
                {title("Contact Us")}
                {text("For any questions regarding this Privacy Policy, please email us at privacy@bellrock.ai.")}

                <Button onClick={handleClose} sx={{ mt: 4, mb: 6, width:'100%',
                    color: themeMode === 'dark' ? '#000' : '#fff',
                    backgroundColor: themeMode === 'dark' ? '#fff' : '#000',}}>
                    {t("acceptTerms")}
                </Button>
            </DialogContent>
        </Dialog>
    );
}


export default PrivacyPolicyDialog;
