import React from "react";
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@mui/material';
import { useNavigate } from "react-router-dom";


export const UnAuthorizedHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    

    const handleSignInClick = () => {
        navigate("/fileDetection/signin");
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

            <ButtonBase onClick={handleSignInClick}>
                <Typography sx={{ mx: 2, display: 'inline-block', p: 1, borderRadius: '4px', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } }} variant="h6">
                    {t('signin')}
                </Typography>
            </ButtonBase>

        </Box>
    );
}

export default UnAuthorizedHeader;