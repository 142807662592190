import axios from "axios";
import {getRequest,deleteRequest, postRequest, logOutAction,setAuthTokenAndUser, getHeader, patchRequest} from "./httpSetup";

import { authURL } from "./apiEndpoints";

export const signup = (fields) => {
  try
  {
    return postRequest(authURL.signup, fields);
  }
  catch (error)
  {
    console.error('Error signing up:', error);
    return error;
  }
}

export const googleAuth = (fields) => {
  try
  {
    return postRequest(authURL.googleAuth, fields, {}, false);
  }
  catch (error)
  {
    console.error('Error google auth:', error);
    return error;
  }
}

export const  signin = (fields) => {
  try
  {
    return postRequest(authURL.signin, fields);
  }
  catch (error)
  {
    console.error('Error signing in:', error);
    return error;
  }
}


export const checkLogin = async () => {
  try
  {
    return getRequest(authURL.checklogin, getHeader());
  }
  catch (error)
  {
    console.error('Error checking login:', error);
    return error;
  }
}

export const checkuser = async (fields) => {
  try
  {
    return postRequest(authURL.checkUser, fields);
  }
  catch (error)
  {
    console.error('Error checking user:', error);
    return error;
  }
}

export const signout = () => {
  try
  {
    return getRequest(authURL.logout, getHeader());
  }
  catch (error)
  {
    console.error('Error signing out:', error);
    return error;
  }
}

export const uploadProfilePicture = async(file) => {


  //return postRequest(authURL.uploadprofilepicture, getHeader());
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios({
      method: 'post',
      url: authURL.uploadprofilepicture,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `${localStorage.getItem('token')}`
      },
      responseType: 'blob',  // Ensures the response is treated as a Blob
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      }
    });
    return response;
  }
  catch (error) {
    console.error('Error uploading profile picture:', error);
    return error;
  }
}

export const getprofilepicture = async () => {
  try
  {
    return getRequest(authURL.getprofilepicture, getHeader());
    
  }
  catch (error)
  {
    console.error('Error getting profile image:', error);
    return error;
  }
}

export const resetPassword = async (fields,url) => {
  try
  {
    return postRequest(authURL.resetPassword+url, fields);
  }
  catch (error)
  {
    console.error('Error resetting password:', error);
    return error;
  }
}

export const requestReset = async (fields) => {
  try
  {
    return postRequest(authURL.requestReset, fields);
  }
  catch (error)
  {
    console.error('Error requesting reset:', error);
    return error;
  }
}

export const deleteUser = async (password) => {
  try
  {
    return deleteRequest(authURL.delete,{password:password}, getHeader(), false);
 
  }
  catch (error)
  {
    console.error('Error deleting user:', error);
    return error;
  }
}

// API keys --------------------------------------------------

export const createAPIKey = (title) => {
  try {
    return postRequest(authURL.apiKeys, { title: title }, getHeader());
  }
  catch (error) {
    console.error('Error deleting user:', error);
    return error;
  }

}
export const getAPIKeys = () => {
  try {
    return getRequest(authURL.apiKeys, getHeader());
  }
  catch (error) {
    console.error('Error deleting user:', error);
    return error;
  }
}

export const updateAPIKey = (id, title) => {
  try {
    return patchRequest(authURL.apiKeys+'/'+id, { title: title }, getHeader());
  }
  catch (error) {
    console.error('Error deleting user:', error);
    return error;
  }
}

export const deleteAPIKey = (id) =>
{
  try {
    return deleteRequest(authURL.apiKeys+'/'+id,{}, getHeader(), false);
  }
  catch (error) {
    console.error('Error deleting user:', error);
    return error;
  }
}





  export default {getAPIKeys,updateAPIKey,deleteAPIKey,createAPIKey,  deleteUser, signup, signin ,checkLogin ,signout, uploadProfilePicture, checkuser ,getprofilepicture, googleAuth ,resetPassword, requestReset}

