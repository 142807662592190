import { lazy } from 'react';

// project imports
import MarketingLayout from '../layouts/MarketingLayout';
import DetectionLayout from '../layouts/DetectionLayout';
import Loadable from '../components/common/Loadable';
import GoogleCallback from '../components/auth/googleCallback';

const MarketingPage = Loadable(lazy(() => import('../components/marketingPage/marketingPage')));
// const Main = Loadable(lazy(() => import('../components/mainPage/main')));
const FileDetection = Loadable(lazy(() => import('../components/fileDetection/fileDetection')));
const HealthCheck = Loadable(lazy(() => import('../components/healthCheck')));
const CommunityPage = Loadable(lazy(() => import('../components/CommunityPage/communityPage')));
const ContactUS = Loadable(lazy(() => import('../components/aboutUs/ContactUS')));
const AboutUS = Loadable(lazy(() => import('../components/aboutUs/AboutUS')));
const AccountSettings = Loadable(lazy(() => import('../components/fileDetection/accountSettings')));
const PaymentSuccsess = Loadable(lazy(() => import('../components/paymentSuccsess')));
const PaymentFail = Loadable(lazy(() => import('../components/paymentFail')));
const Articles = Loadable(lazy(() => import('../components/ArticlesPage/articles'))); 
const News = Loadable(lazy(() => import('../components/NewsPage/news')));
const Insights = Loadable(lazy(() => import('../components/InsightsPage/insights')));

const CardAddSuccsess = Loadable(lazy(() => import('../components/cardAddSuccsess')));
const CardAddFailed = Loadable(lazy(() => import('../components/cardAddFailed')));
const Main = Loadable(lazy(() => import('../components/mainPage/main')));

const Community = Loadable(lazy(() => import('../components/community/community')));
const Dashboard = Loadable(lazy(() => import('../components/dashboard/dashboard')));  


// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MarketingLayout />,
  children: [

    {
      path: '/',
      element: <Main />
    },
    {
      path: 'communitypage',
      element: <Community /> 
    },
    {
      path: '/:position',
      element: <Main />
    },
    {
      path: '/paymentsuccsess',
      element: <PaymentSuccsess />
    },
    {
      path: '/CardAddSuccsess',
      element: <CardAddSuccsess />
    },
    {
      path: '/CardAddFailed',
      element: <CardAddFailed />
    },
    {
      path: '/paymentfail',
      element: <PaymentFail />
    },
    {
      path: '/health',
      element: <HealthCheck />
    },
    {
      path: 'auth/google/callback',
      element: <GoogleCallback />
    },
    {
      path: 'auth/:signup',
      element: <Main />
    },
    {
      path: 'auth/:signin',
      element: <Main />
    },
    {
      path: 'auth/:signupsimple',
      element: <Main />
    },
    {
      path: 'auth/:resetpassword',
      element: <Main />
    },
    {
      path: 'auth/:resetpassword/:token',
      element: <Main />
    },
    {
      path: 'auth/:terms',
      element: <Main />
    },
    {
      path: '/community',
      element: <CommunityPage />
    },
    {
      path : '/insights',
      element : <Insights />
    },
    {
      path: '/aboutus',
      element: <AboutUS />
    },
    {
      path: '/contactus',
      element: <ContactUS />
    },
    {
      path: '/accountsettings',
      element: <AccountSettings />
    },
    {
      path: '/articles/:articleId',
      element: <Articles />
    },
    {
      path: '/articles',
      element: <Articles />
    },
    {
      path: '/News',
      element: <News />
    },
    {
      path: '/communitypage',
       element: <MarketingLayout />,
   },
   {
    path: 'dashboard-demo-1251',
    element: <Dashboard />,
  }
  ]
};

const mainPage = {
    path :'/main', 
    element : <Main />
    
};


const DetectionRoutes = {
  path: 'fileDetection',
  element: <DetectionLayout />,
  children: [
    {
      path: '',
      element: <FileDetection />
    },
    {
      path: ':options',
      element: <FileDetection />
    },
    {
      path: ':options/:suboptions',
      element: <FileDetection />
    }
    
  ]
};


export { MainRoutes, DetectionRoutes ,mainPage};
