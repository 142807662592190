import React, { createContext, useContext, useState, useEffect } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import getTheme from '../themes';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);


export const ThemeProvider = ({ children }) => {
  // Initialize state with theme mode from local storage or fall back to 'light'
  const [themeMode, setThemeMode] = useState(() => {
    const storedThemeMode = localStorage.getItem('themeMode');
    return storedThemeMode ? storedThemeMode : 'light';
  });


  // Effect to store theme mode in local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode]);

  const toggleTheme = () => {
    setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
  };

  const theme = getTheme(themeMode);

  return (
    <ThemeContext.Provider value={{ toggleTheme, themeMode }}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
