import { env } from '../env'
export const BASE_URL =  env.REACT_APP_BACKEND_DOMAIN;
export const API_v2_URL = "/api"
export const API_FULL_URL = BASE_URL + API_v2_URL;


export const authURL = {
    "signin": API_FULL_URL + "/users/signin",
    "signup": API_FULL_URL + "/users/signup",
    "signout": API_FULL_URL + "/users/signout",
    "checkUser": API_FULL_URL + "/users/checkuser",
    "checklogin": API_FULL_URL + "/users/checklogin",
    "uploadprofilepicture": API_FULL_URL + "/users/uploadprofilepicture",
    "getprofilepicture": API_FULL_URL + "/users/getprofilepicture",
    "googleAuth": API_FULL_URL + "/users/googleAuth",
    "appleAuth": API_FULL_URL + "/users/appleAuth",
    "requestReset": API_FULL_URL + "/users/request-reset",
    "resetPassword": API_FULL_URL + "/users/reset-password",
    "editme": API_FULL_URL + "/users/edit-me",
    "delete": API_FULL_URL + "/users",
    "apiKeys": API_FULL_URL + "/users/apikeys",

}
export const fileDetectionURL = {
    "uploadfile": API_FULL_URL + "/detection/uploadfile",
    "detectfile": API_FULL_URL + "/detection/detectfile",
    "gethistory": API_FULL_URL + "/detection/history",
    "getfile": API_FULL_URL + "/detection/file",
}

export const blogsURL = {
    "getblogs": API_FULL_URL + "/articles?page=0&size=100",
    "getBlog": API_FULL_URL + "/articles/",
}

export const paymentURL = {
    "creditInfo": API_FULL_URL + "/credit/info",
    "createCheckout":API_FULL_URL + "/credit/create-checkout-session",
    "setupPaymentMethod":API_FULL_URL + "/credit/setup-payment-method",
    "setdefailtcard":API_FULL_URL +   "/credit/set-defailt-card",
    "deleteCard":API_FULL_URL + "/credit/delete-card",
    "getCards":API_FULL_URL + "/credit/cards",
    "getCreditCustomer":API_FULL_URL + "/credit/customer",
    "getPaymentHistory": API_FULL_URL +   "/credit/history",
    "getSession": API_FULL_URL+ '/credit/session',
    "autoRenewal": API_FULL_URL + "/credit/auto-renewal",
    "setupAutoRenewal": API_FULL_URL + "/credit/setup-auto-renewal",

}

export default { authURL,fileDetectionURL, API_FULL_URL }
