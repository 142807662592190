import { useState, useCallback } from 'react';

export const useNotification = () => {
  const [notification, setNotification] = useState(null);
  const [dialogNotification, setDialogNotification] = useState(null);

  const showNotification = useCallback((message, type = 'info') => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  }, []);

  const showNotificationDialog = useCallback((message, title = 'Notification', type = 'info',actionBtn=false,action="") => {
    setDialogNotification({ message, title, type, actionBtn, action });
  }, []);

  const clearNotification = useCallback(() => {
    setNotification(null);
  }, []);

  const clearDialogNotification = useCallback(() => {
    setDialogNotification(null);
  }, []);

  return {
    notification,
    dialogNotification,
    showNotification,
    showNotificationDialog,
    clearNotification,
    clearDialogNotification,
  };
};

export default useNotification;
