import { useRoutes } from 'react-router-dom';

// routes
import { MainRoutes, DetectionRoutes,mainPage } from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, DetectionRoutes,mainPage]);
}
