// src/App.js
import React,  { useEffect } from 'react'; 
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from './routes';
import { UserProvider } from './contexts/userContext';
import { ThemeProvider } from './contexts/themeContext';
import './App.css';
import { NotificationProvider } from './contexts/notificationContext';

export const App = () => {


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
      <NotificationProvider>
        <CssBaseline />
        <UserProvider>
          <Routes />
        </UserProvider>
        </NotificationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
