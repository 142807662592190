import React, {useEffect, useState} from "react";
import { Box, margin } from '@mui/system';
import { Typography, ButtonBase, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {ProfileMenu} from './profileMenu';
import { useUser } from '../../../contexts/userContext';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useTheme } from '../../../contexts/themeContext';
import Switch from '@mui/material/Switch';
import CachedIcon from '@mui/icons-material/Cached';
import { checkLogin } from '../../../services/AuthService';



export const UserProfile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
const { themeMode,toggleTheme  } = useTheme();

const ButtonStyle = {
  background: '#2700C1',
  border: `1px solid ${themeMode === 'dark' ? '#fff' : '#2700C1'}`,
  color: `${themeMode === 'dark' ? '#fff' :'#fff'}`,
  borderRadius: '50px',
  '&:hover': {
    backgroundColor:'#2700DA' // 50% transparency of the specified color on hover
  }
}

    const { user, credit, calculateCredit} = useUser();

    useEffect(() =>{ calculateCredit() }, []);

    const handleDetectClick = () => {
        navigate("/fileDetection");
    };

    const handleMessagesClick = () => {
        console.log("Messages clicked");
    };

    const handleNotificationsClick = () => {
        console.log("Notifications clicked");
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
           
            <CachedIcon   style={{ margin:"8px",  marginTop: '4px', fontSize: '16px', cursor: 'pointer', color: 'green' }} onClick={() => { calculateCredit() }}/> 
            <Typography sx={{ m: 2, fontSize: '16px', width: '80px', ml: 0 }} variant="h1" component="h1">
                Credits {credit}
            </Typography>

            <Button variant="outlined" onClick={()=>navigate("/fileDetection/baycredits")} sx={{ ...ButtonStyle, m: 2, width: '110px' }} >
             Buy credits
          </Button>
            {/* <Switch
                checked={themeMode === 'dark'}
                onChange={toggleTheme}
                color="default"
            /> */}

            <Box sx={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <ProfileMenu />
            </Box>
        </Box>
    );
}
