
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, height, width } from '@mui/system';
import { UserProfile } from '../components/fileDetection/header/userProfile'
import { useUser } from '../contexts/userContext';
import {UnAuthorizedHeader} from '../components/fileDetection/header/unAutorizedHeader';
import {Header} from '../components/fileDetection/header/header';

const DetectionLayout = ({ children }) => {

    const { user } = useUser();
   

    return (
        <>
            {/* <Header endComponent={() =>
             ((typeof user === 'object' && user !== null) ? <UserProfile /> : <UnAuthorizedHeader/>
            )} />
            {children} */}
       

            <Box sx={{  }} >
                <Outlet />
            </Box>
        </>
    );
};

export default DetectionLayout;
