// src/context/UserContext.js
import React, { createContext, useState, useContext,useEffect } from 'react';
import AuthService, { checkLogin } from '../services/AuthService';
import { useNavigate, useLocation } from 'react-router-dom';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userIsLogedIn, setUserIsLogedIn] = useState(false);  
    const [credit, setCredit] = useState(0);

    const saveTokens = (token, user) => {
        setUser(user);
        setToken(token);
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
    };


    useEffect(() => {
        const checkLogin = async () => {
            if(location.pathname !== '/auth/google/callback'){
                const res = await AuthService.checkLogin();
                if (res.token && res.user) {
                    setToken(res.token);
                    setUser(res.user);
                    setUserIsLogedIn(true);
                    setCredit(res.user.credit);
                   // localStorage.setItem('token', res.token);
                   // localStorage.setItem('user', JSON.stringify(res.user));
                } else {
                    setUserIsLogedIn(false);
                    setToken(null);
                    setUser(null);
                    setCredit(0);
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');                
                }
            }
        }
     //   checkLogin();
    }, []);


    const signIn = async (signInData) => {
        // Implement your sign in logic here, possibly using AuthService
        try {
            const data = await AuthService.signin(signInData);

            if (data.token && data.user) {
                saveTokens( data.token,data.user);
                setUserIsLogedIn(true);
                return data;
            }
            else {
                console.log('Error signing in:', data);
                return data;
            }
        }
        catch (error) {
            console.error('Error signing in:', error);
            return error;
        }

    };

    const signOut = () => {
        // Implement sign out logic
        AuthService.signout();
        setUser(null);
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUserIsLogedIn(false);
        navigate('/');
    };

    const signUp = async (signUpData) => {
        try {
            const data = await AuthService.signup(signUpData);
            if (data.token && data.user) {
                saveTokens( data.token,data.user);
                setUserIsLogedIn(true);
                return data;
            }
            else {
                console.log('Error signing up:', data);
                return data;
            }
        }
        catch (error) {
            console.error('Error signing up:', error);
            return error;
        }
    };

    const googleAuth = async (googleAuthData) => {
        try {
            const data = await AuthService.googleAuth(googleAuthData);
            if (data.token && data.user) {
                saveTokens( data.token,data.user);
                setUserIsLogedIn(true);
                return data;
            }
            else {
                console.log('Error google auth:', data);
                return data;
            }
        }
        catch (error) {
            console.error('Error google auth:', error);
            return error;
        }
    };

    const calculateCredit = async () => {
        const res = await AuthService.checkLogin();
        if (res.token && res.user) {
            setCredit(res.user.credit);
        }
    }

    const updateUser = async (updateData) => {
        try {
            const data = await AuthService.checkLogin();
            if (data.user) {
                setUser(data.user);
                localStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('token', data.token);
                return data;
            } else {
                console.log('Error updating user:', data);
                return data;
            }
        } catch (error) {
            console.error('Error updating user:', error);
            return error;
        }
    };

    return (
        <UserContext.Provider value={{updateUser,userIsLogedIn, user, token, credit, signIn, signOut, signUp, googleAuth, calculateCredit }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
