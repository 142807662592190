import axios from "axios";




export const setAuthTokenAndUser = (token,user) => {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = token;
    }
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    }
  
  };
    
  
  export const getHeader = () => {
    try {
      const token = localStorage.getItem("token");
      return {
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
      };
    } catch {
      console.log("can not read token@!");
      return {
        headers: {
          "Content-Type": "application/json",
        },
      };
    }
  };


export const logOutAction = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");

}



export const checkAndLogout = (response) => {
  if (typeof response?.data?.message !== "undefined") {
    if (response?.data?.message === 'Invalid Token' || response?.data?.message === "Access Denied") {
      logOutAction()
    }
  }
}


const checkAut = (err) => {
  if (err?.request?.status === 401) {
    console.log(err?.request?.status)
    if (window.location.pathname === "/" || window.location.pathname.toLocaleLowerCase() === "/auth/signin" || window.location.pathname.toLocaleLowerCase() === "/auth/signup")
      return;
    else{
      window.location.href = "/";
      logOutAction()   
    }
     
  }
}

export const getRequest = async (url, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.get(url, config);
    statusCode = res.status;
    if (checkLogin) checkAndLogout(res);
    return res.data;

  } catch (err) {
    checkAut(err)
    if (checkLogin) checkAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};



export const postRequest = async (url, data, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.post(url, data, config);
    statusCode = res.status;
    if (checkLogin) checkAndLogout(res);
    return res.data;
  } catch (err) {
    checkAut(err)
    if (checkLogin) checkAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};


export const putRequest = async (url, data, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.put(url, data, config);
    statusCode = res.status;
    if (checkLogin) checkAndLogout(res);
    return res.data;
  } catch (err) {
    checkAut(err)
    if (checkLogin) checkAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

export const deleteRequest = async (url, data, config, checkLogin = false) => {
  let statusCode;
  try {
    // Include the data in the config object
    console.log(data)
    const res = await axios.delete(url, { data: {...data }, headers: config['headers'] } );
    statusCode = res.status;
    if (checkLogin) checkAndLogout(res);
    return res.data;
  } catch (err) {
  //  checkAuth(err);
    if (checkLogin) checkAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

// patch request
export const patchRequest = async (url, data, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.patch(url, data, config);
    statusCode = res.status;
    if (checkLogin) checkAndLogout(res);
    return res.data;
  } catch (err) {
    checkAut(err)
    if (checkLogin) checkAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

export default {patchRequest,deleteRequest, getRequest, postRequest, logOutAction,setAuthTokenAndUser, getHeader } 

