import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useTheme as useThemeG } from '@mui/material/styles';
import { useUser } from '../../../contexts/userContext';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import Notification from '../../notifications';
import { useTheme } from '../../../contexts/themeContext';



export const Header = ({ startComponent, endComponent, handleDrawerToggle }) => {
  const { t, i18n } = useTranslation();

  const theme = useThemeG();
  const { user } = useUser();
  const { signup } = useParams();
  const { themeMode } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };


  return (
    <>

      <AppBar elevation={0} sx={{ height: '58px', width: 'calc(100% - 40px)' }} position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>

          {isMobile && (
            <IconButton
              color="inherit"
              aria-label={t('openDrawer')}
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ m: 2 }}>
              <MenuIcon />
            </IconButton>
          )}


          {!isMobile && user?.first_name && <Box sx={{ display: 'flex', alignItems: 'center' }} >
            <Typography sx={{
              m: 2,
              fontSize: '24px',
              width: 'auto',
              display: 'inline-block',
              whiteSpace: 'nowrap', 
            }}
              variant="h1" component="h1">
              {t('welcomeMessage')} {user?.first_name ||  ''}
            </Typography>
          </Box>}
          
          <Box sx={{width:'100%', ml:5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Notification />
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {endComponent ? endComponent() : null}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default { Header };


