import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './en/translation.json';
import translationES from './es/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // use language detector
  .init({
    resources,
    lng: 'en', // initial language
    fallbackLng: 'en', // use en if detected lng is not available

    detection: {
      order: ['localStorage', 'navigator'], // define the order of methods to detect the language
      caches: ['localStorage'] // cache user language in local storage
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
