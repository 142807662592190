import React, { useEffect, useState } from 'react';
import {
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/userContext';

export const GoogleCallback = () => {
    const { googleAuth } = useUser();
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
          googleAuth({ code })
            .then(response => {
              console.log("===== response", response)
              // Handle login success, store access tokens, etc.
              if(response?.response?.data?.error){
                setError(response.response.data.error);
                setTimeout(() => {
                    window.location.href = '/'
                }, 3000)
              }
              else if(response?.message){
                setError(response.message);
                setTimeout(() => {
                    window.location.href = '/'
                }, 3000)
              }
              else{
                window.location.href = '/fileDetection'
              }
            })
            .catch(error => {
              // Handle errors
              console.log("===== error", error)
              window.location.href = '/'
            });
        
        }
    }, []);

    return (
    <div >
        <Typography variant="body2" sx={{ py: 1, color: 'red',  alignItems: 'center', justifyContent: 'center'}} >
            <Typography variant="body2" sx={{ py: 1, color: 'red', width: '500px', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '20px'}} >
                {error}
            </Typography>
            <Typography variant="body2" sx={{ py: 3,fontSize:'16px', color: '#888', width: '200px', margin: 'auto', textAlign: 'center'}}>
                {t('Processing login...')}
            </Typography>
        </Typography>
    </div>
    );
}

export default GoogleCallback;
