
import React, { useState } from 'react';

import { useUser } from '../contexts/userContext';
import { Outlet } from 'react-router-dom';
import { TopHead } from '../components/marketingPage/topHead';
import { Footer } from '../components/marketingPage/footer';
import PrivacyPolicyDialog from '../components/dialogs/privacyPolicyDialog';
import { Terms } from '../components/dialogs/terms';



const MarketingLayout = ({ children }) => {

    const { user } = useUser();
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);  // State to control the open/close of the dialog
    const [openTerms, setOpenTerms] = useState(false);  // State to control the open/close of the dialog

    return (
        <>
            {/* <TopHead /> */}
            <Terms open={openTerms} setOpen={setOpenTerms} />
            <PrivacyPolicyDialog open={openPrivacyPolicy} setOpen={setOpenPrivacyPolicy} />
            <Outlet />
            {/* <Footer onClickPrivacyPolicy={() => setOpenPrivacyPolicy(true)} onClickTerms={() => setOpenTerms(true)} /> */}
        </>
    );
};

export default MarketingLayout;
