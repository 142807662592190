import React, {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/userContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTheme } from '../../../contexts/themeContext';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { use } from 'i18next';
import { getprofilepicture } from '../../../services/AuthService';
import { authURL } from '../../../services/apiEndpoints';
//import profileImage from '../../../assets/images/ProfilePicturesExamples/1.png';


export function ProfileMenu() {
    const navigate = useNavigate();
    const { user, signOut , token} = useUser();
    const [anchorEl, setAnchorEl] = useState(null);
    const { themeMode, toggleTheme } = useTheme();
    const [profileImage, setProfileImage] = useState(null);


    const fetchPhoto = async (imageUrl) => {
        try {
            const response = await fetch(authURL.getprofilepicture, {
                headers: {
                    'Authorization': `${token}`
                }
            });
            const blob = await response.blob();
            return URL.createObjectURL(blob); // Directly return the URL from this function
        } catch (error) {
            console.error('Failed to fetch image:', error);
            return ''; // Return empty string or a default image path in case of error
        }
    };

    useEffect(() => {
        if (user) {
            const fetchProfileImage = async () => {
                const response = await getprofilepicture();
                if (response) {
                    const image = await fetchPhoto(response.data);
                    setProfileImage(image);
                }
            };
            fetchProfileImage();
        }
    }   , [user]);


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSignOut = () => {
        signOut();
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                //onClick={handleClick}
            >
                {profileImage && <img src={profileImage} alt="Avatar" style={{ width: "38px", height: "38px", borderRadius: "50%" }} /> }


            </IconButton>
            <Typography sx={{ mx: 1,}} variant="body2">
                    {user ? user.first_name :user.email }
            </Typography>
                {<ArrowBackIosNewIcon fontSize="small"  onClick={handleClick} sx={{ transform: open ? 'rotate(90deg)' : 'rotate(270deg)' }} />}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >               
                <MenuItem onClick={() => { handleClose(); navigate('/fileDetection/baycredits'); }}>Buy Credits</MenuItem>  
                <MenuItem onClick={() => { handleClose(); navigate('/fileDetection/paymentSettings'); }}>Payment Settings</MenuItem>  
                <MenuItem onClick={() => { handleClose(); navigate('/fileDetection/profileSettings'); }}>Profile Settings</MenuItem> 
                <MenuItem onClick={() => { handleClose(); navigate('/fileDetection/apikeys'); }}>API Key</MenuItem> 
                <MenuItem sx={{color:themeMode === 'dark' ? "#fff" : "#251BD9"  }} onClick={() => { handleClose(); handleSignOut(); }}>Log out</MenuItem>
            </Menu>
        </Box>
    );
}

export default ProfileMenu;
