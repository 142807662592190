// themes.js
import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => {
  return createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === 'dark' ? '#1A1C22' : '#fff',
      },
      background: {
        default: mode === 'dark' ? '#1A1C22' : '#fff',
      },
      // other theme properties
    },
    typography: {
      fontFamily: "FixelDisplay",
      h6: {
        fontWeight: 500,
        color: mode === 'dark' ? '#fff' : '#111010',
        fontSize: '0.75rem'
      },
      h5: {
        fontSize: '0.875rem',
        color: mode === 'dark' ? '#fff' : '#111010',
        fontWeight: 500
      },
      h4: {
        fontSize: '1rem',
        color: mode === 'dark' ? '#fff' : '#111010',
        fontWeight: 600
      },
      h3: {
        fontSize: '1.25rem',
        color: mode === 'dark' ? '#fff' : '#111010',
        fontWeight: 600
      },
      h2: {
        fontSize: '1.5rem',
        color: mode === 'dark' ? '#fff' : '#111010',
        fontWeight: 700
      },
      h1: {
        fontSize: '2.125rem',
        color: mode === 'dark' ? '#fff' : '#111010',
        fontWeight: 700
      },
      h1p: {
        fontSize: '2.125rem',
        color: mode === 'dark' ? '#fff' : '#220186',
        fontWeight: 700
      },
      h2p: {
        fontSize: '1.5rem',
        color: mode === 'dark' ? '#fff' : '#220186',
        fontWeight: 700
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: mode === 'dark' ? '#fff' : '#111010'
      },
      subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: mode === 'dark' ? '#fff' : '#111010'
      },
      caption: {
        fontSize: '0.75rem',
        color: mode === 'dark' ? '#fff' : '#111010',
        fontWeight: 400
      },
      body1: {
        fontSize: '0.875rem',
        color: mode === 'dark' ? '#fff' : '#6C718A',
        fontWeight: 400,
        lineHeight: '1.334em'
      },
      body2: {
        letterSpacing: '0em',
        fontWeight: 400,
        lineHeight: '1.5em',
        color: mode === 'dark' ? '#fff' : '#6C718A'
      },

      // other typography properties
    }, 
    // switch component to dark mode
 
    components: {
      // Overrides for InputBase
      
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#1A1C22' : '#fff',
            color: mode === 'dark' ? '#fff' : '#1A1C22',
            '&.Mui-focused': {
              borderColor: mode === 'dark' ? '#fff' : '#1A1C22',
            }
          },
          input: {
            '&::placeholder': {
              color: mode === 'dark' ? '#CFD1DA' : '#888',
            }
          }
        }
      },
      // Overrides for OutlinedInput
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            borderColor: `1px solid ${mode === 'dark' ? '#CFD1DA' : '#ccc'}`,  
            
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: mode === 'dark' ? '#CFD1DA' : '#ccc',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: mode === 'dark' ? '#fff' : '#1A1C22',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: mode === 'dark' ? '#fff' : '#1A1C22',
            }
          }
        }
      },
      // Overrides for Switch
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: mode === 'dark' ? '#CFD1DA' : '#ccc',
            
          }
        }
      },
      // Overrides for Button
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "50px",
            border: `1px solid ${mode === 'dark' ? '#CFD1DA' : '#ccc'}`,
            textTransform: 'none',
            backgroundColor: mode === 'dark' ? '#333' : '#e0e0e0',
            color: mode === 'dark' ? '#fff' : '#111010',
            '&:hover': {
              backgroundColor: mode === 'dark' ? '#555' : '#ccc',
            },
            '&:active': {
              boxShadow: mode === 'dark' ? 'inset 0 3px 5px rgba(0,0,0,0.125)' : 'inset 0 3px 5px rgba(0,0,0,0.1)',
            }
          },
          outlined: {
            borderColor: mode === 'dark' ? '#CFD1DA' : '#ccc',
            '&:hover': {
              backgroundColor: mode === 'dark' ? '#454545' : '#d6d6d6',
              borderColor: mode === 'dark' ? '#fff' : '#888',
            }
          },
          contained: {
            backgroundColor: mode === 'dark' ? '#666' : '#fff',
            '&:hover': {
              backgroundColor: mode === 'dark' ? '#777' : '#eee',
            }
          },
          text: {
            color: mode === 'dark' ? '#DDD' : '#555',
            '&:hover': {
              backgroundColor: mode === 'dark' ? '#555' : '#ccc',
            }
          }
        }
      },
      // Overrides for Paper
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ?"#1A1C22" : '#fff',
            color: mode === 'dark' ? '#fff' : '#111010',
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: mode === 'dark' ? '#CFD1DA' : '#ccc', // default unchecked color
            '&.Mui-checked': {
              color: mode === 'dark' ? '#fff' : '#1A1C22', // checked color
            },
            '&:hover': {
              backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)', // hover background
            }
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: 8, // Set the thickness of the LinearProgress bar
            borderRadius: 5, // Optional: if you want rounded corners
            backgroundColor: mode === 'dark' ? '#555' : '#190080', // Lighter background in both themes
          },
          bar: {
            borderRadius: 5, // Optional: if you want rounded corners on the bar as well
            backgroundColor: mode === 'dark' ? '#CFD1DA' : '#fff', // Bar color
          },
          dashed: {
            backgroundImage: mode === 'dark' ? 'radial-gradient(#CFD1DA 0%, #CFD1DA 16%, transparent 42%)' : 'radial-gradient(#190080 0%, #190080 16%, transparent 42%)'
            // Only necessary if you use the "dashed" variant
          },
        },
      }, 
      
    },

    // any other customizations
  });
};

export default getTheme;
