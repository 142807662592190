import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';

import './i18n/i18n'; // Initialize i18n configuration

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  
    <BrowserRouter >
      <App />
    </BrowserRouter>

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
